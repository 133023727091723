import * as React from 'react';

import { StyledElementProps } from './StyledComponent.types';

/**
 * StyledElement with no default styles.
 * [Use the `styles` API to add your own styles.](https://github.com/OfficeDev/office-ui-fabric-react/wiki/Component-Styling)
 */
export const StyledElementBase = ({
  attributes,
  children,
  className,
  htmlAttributes,
  as = 'div',
}: StyledElementProps) => {
  const StyledElementBaseMain = as;

  return (
    <StyledElementBaseMain {...attributes} className={className} {...htmlAttributes}>
      {children}
    </StyledElementBaseMain>
  );
};

export const StyledElement = StyledElementBase;
